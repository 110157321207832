<template>
	<input
		v-model="model"
		class="toggle-input appearance-none w-9 rounded-full h-5 align-top bg-white bg-no-repeat bg-contain focus:outline-none cursor-pointer shadow-sm"
		type="checkbox"
		role="switch"
	/>
</template>

<script>
export default {
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		model: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
	},
};
</script>

<style lang="scss" scoped>
.toggle-input {
	margin-top: 0.1rem;
	background-color: #eee;
	background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%223%22 fill=%22%23fff%22/%3E%3C/svg%3E");
	background-position: 0;
	transition: background-position 0.15s ease-in-out;
}
.toggle-input:checked {
	background-position: 100%;
	background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%223%22 fill=%22%23fff%22/%3E%3C/svg%3E");
}
.toggle-input:checked[type="checkbox"] {
	background-color: var(--brand-color);
}
</style>